import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import TextMessage from './TextMessage';
import EnquiryOptions from './EnquiryOptions/EnquiryOptions';
import EnquiryOptionsSA from './EnquiryOptions/EnquiryOptions-SA';
import SelectedEnquiryOptions from './EnquiryOptions/SelectedEnquiryOptions';
import SelectedRequestOptions from './EnquiryOptions/SelectedRequestOptions';
import UserInput from './../ais_components/UserInput';
import ConfirmCurrentDevice from './ConfirmCurrentDevice/confirmCurrentDevice';
import AcceptTerms from './acceptTerms/acceptTerms';
import ResumeServiceRequest from './ResumeServiceRequest/ResumeServiceRequest';
import CheckAlternativeDevice from './CheckAlternativeDevice/checkAlternativeDevice';
import ConfirmDeliverySchedule from './ConfirmDeliverySchedule/confirmDeliverySchedule';
import ConfirmEnrolledAddress from './ConfirmEnrolledAddress/confirmEnrolledAddress';
import ConfirmSelectedDevice from './ConfirmSelectedDevice/confirmSelectedDevice';
import ConfirmYourDetails from './ConfirmYourDetails/confirmYourDetails';
import CurrentRequestDetails from './CurrentRequestDetails/currentRequestDetails';
import CustomerSupportChat from './CustomerSupportChat/customerSupportChat';
import DropMessage from './DropMessage/dropMessage';
import GridViewOptions from './GridViewOptions/GridViewOptions';
import ProceedServiceFee from './ProceedServiceFee/proceedServiceFee';
import SentMessage from './SentMessage/sentMessage';
import SubmitContactNumber from './SubmitContactNumber/submitContactNumber';
import SubmitIMEICode from './SubmitIMEICode/submitIMEICode';
import TurnOffFeatures from './TurnOffFeatures/TurnOffFeatures';
import WebCreditCardForm from './EnterCreditCardDetails/enterCreditCardDetails';
import WebCreditCardFormNew from './EnterCreditCardDetails/enterCreditCardDetailsNew';
import WebChangeDeliveryAddress from './ChangeDeliveryAddress/changeDeliveryAddress';
import AskFeedback from './AskFeedback/AskFeedback';
import SurveyQuestion from './SurveyQuestion/SurveyQuestion';
import ChangeDeliverySchedule from './ChangeDeliverySchedule/changeDeliverySchedule';
import SlideAlternativeDevices from './SlideAlternativeDevices/SlideAlternativeDevices';
import InformativeMessage from './InformativeMessage/InformativeMessage';
import InformativeResumeMessage from './InformativeResumeMessage/InformativeResumeMessage';
import DesktopAgentStatus from './DesktopAgentStatus/DesktopAgentStatus';
import CurrentDeviceOptions from './CurrentDeviceOptions/CurrentDeviceOptions';
import AjaxLoader from './AjaxLoader/AjaxLoader';
import DisclaimerWithButtons from './DisclaimerWithButtons/DisclaimerWithButtons';
import TurnOffFindMyPhone from './TurnOffFindMyPhone/TurnOffFindMyPhone';
import FMIPInstructionSlider from './FMIPInstructionSlider/FMIPInstructionSlider';
import WebTrackMyDelivery from './TrackMyDelivery/trackMyDelivery';
import DownloadDocument from './DownloadDocument/DownloadDocument';
import UploadDocument from './UploadDocument/UploadDocument';
import SelectUploadDocument from './SelectUploadDocument/SelectUploadDocument';
import UploadSRForm from './UploadSRForm/UploadSRForm';
import UploadFileScript from './UploadFileScript/UploadFileScript';
import DisplayScript from './DisplayScript/DisplayScript';
import DisabledProceedServiceFee from './DisabledProceedServiceFee/disabledProceedServiceFee';
import ResumeReqForHold from './ResumeReqForHold/ResumeReqForHold';
import AskForEnquiry from './AskForEnquiry/AskForEnquiry';
import ChangeDeliveryScheduleEnquiry from './ChangeDeliveryScheduleEnquiry/ChangeDeliveryScheduleEnquiry';
import ChangeDeliveryAddressEnquiry from './ChangeDeliveryAddressEnquiry/ChangeDeliveryAddressEnquiry';
import CancelDeliveryEnquiry from './CancelDeliveryEnquiry/CancelDeliveryEnquiry';
import ContinueBotFlow from './ContinueBotFlow/ContinueBotFlow';
import RequestForMDNAndNRIC from './RequestForMDNAndNRIC/RequestForMDNAndNRIC';
import TextAreaWithSubmit from './TextAreaWithSubmit/TextAreaWithSubmit';
import TriageConfirmBox from './TriageConfirmBox/TriageConfirmBox';
import FAQPage from './FAQPage/FAQPage';
import RequestForLeavingAisReason from './RequestForLeavingAisReason/RequestForLeavingAisReason';
import AISReasonCommonPage from './AISReasonCommonPage/AISReasonCommonPage';
import AISSubReasonPage from './AISReasonCommonPage/AISSubReasonPage';
import AISThankYouPage from './AISReasonCommonPage/ThankYoupage';
import Acknowledge from './AISReasonCommonPage/LeavingAISAcknowledge';
import RouteToSveDesk from './AISReasonCommonPage/RouteToSavedesk';
import RouteToRefund from './AISReasonCommonPage/RouteToRefund';
import OtherReason from './AISReasonCommonPage/OtherReason';
import WbkoTrackMyDelivery from './Wbko/TrackMyDelivery';
import WbkoUpdateContactDetails from './Wbko/WbkoUpdateContactDetails';
import ChurnPopup from './AISReasonCommonPage/ChurnPopup';
import WbkoTrackOrEnd from './Wbko/TrackOrEnd';
import WbkoCancellationReason from './Wbko/CancelationReason';

//import ModalCreditCardForm from '../../../device/ModalCCDetails/ModalCCDetails';
//import ModalDeliveryAddress from '../../../device/ModalDeliveryAddress/ModalDeliveryAddress';
//import ModalDeliverySchedule from '../../../device/ModalDeliverySchedule/ModalDeliverySchedule';
//import ModalSelectUploadDoc from '../../../device/ModalSelectUploadDoc/ModalSelectUploadDoc';
//import ModalAgentStatus from '../../../device/ModalAgentStatus/ModalAgentStatus';
//import DeviceTrackMyDelivery from '../../../device/TrackMyDelivery/trackMyDelivery';

import DeviceInStock from './DeviceStockStatus/DeviceInStock';
import DeviceOutStock from './DeviceStockStatus/DeviceOutStock';
import DeviceNLP from './DeviceStockStatus/DeviceNLP';
import DeviceNCA from './DeviceStockStatus/DeviceNCA';

import WbkoTrackMyDeliveryNew from './WbkoNew/WbkoTrackMyDeliveryNew';
import WbkoCancelationConfirm from './WbkoNew/WbkoCancelationConfirm';
import WbkoCancelationReasonNew from './WbkoNew/WbkoCancelationReasonNew';
import WbkoChangeDeliveryAddressNew from './WbkoNew/WbkoChangeDeliveryAddressNew';
import WbkoChangeDeliveryScheduleNew from './WbkoNew/WbkoChangeDeliveryScheduleNew';

import {
  getFormattedPopularName,
  replacePlaceholders,
  replaceKeywordsInComponent,
} from '../utils/utils';
import { setInputState, setJourneyCmpltd } from '../actions/Dashboard/doFindAgreements';
import MESSAGE_CONSTANTS from '../config/journey-messages';
import CONSTANTS from '../utils/Constants';
import { MIXPANEL } from '../mixpanel/Mixpanel';
import EVENTLIST from '../mixpanel/Eventlist';
import GeneralEnquiry from './GeneralEnquiry/GeneralEnquiry';
import CODForCreditCard from './CODForCreditCard/CODForCreditCard';
import GetServiceFee from './GetServiceFee/GetServiceFee';
import GeneralEnquiryReason from './GenaralEnquiryReason/GeneralEnquiryReason';
import GetMoreInfo from './GetMoreInfo/GetMoreInfo';
import SubscriptionDetails from './SubscriptionDetails/SubscriptionDetails';
import StartRequest from './StartRequest/StartRequest';
import MDNIncorrect from './MDNIncorrect/MDNIncorrect';
import DeviceList from './SubscriptionDetails/DeviceList';
import UpdateEnrolledDevice from './UpdateEnrolledDevice/UpdateEnrolledDevice';
import EnrolledDeviceInquiry from './EnrolledDeviceInquiry/EnrolledDeviceInquiry';
import VerifiedDeviceList from './SubscriptionDetails/VerifiedDeviceList';
import ServiceUnavailable from './ServiceUnavailable/ServiceUnavailable';
import SmartStock from './SmartStock/SmartStock';
import { formatCapacity } from './SmartStock/functions';

class Message extends Component {
  _renderMessageOfType(type) {
    let finalMessage = null;
    let props = this.props;
    let componentMsgObj = props.message.data;
    componentMsgObj =
      componentMsgObj && _.isObject(componentMsgObj) && !componentMsgObj.text
        ? replaceKeywordsInComponent(componentMsgObj, '{Username}', props.customerName.firstName)
        : componentMsgObj;

    switch (type) {
      case CONSTANTS.TEXT:
        if (props.message.source === CONSTANTS.SOURCE_ENUM.INTERNAL) return null;
        let messageBlockClass =
          props.message.source === CONSTANTS.SOURCE_ENUM.USER
            ? 'ais_sent-message-blk'
            : 'ais_message-blk';
        let messageDivClass =
          props.message.source === CONSTANTS.SOURCE_ENUM.USER ? 'ais_sent-message' : 'ais_message';
        let messageTimeClass =
          props.message.source === CONSTANTS.SOURCE_ENUM.USER
            ? 'ais_sent-msg-time'
            : 'ais_msg-time';
        let contentClass =
          props.message.source === CONSTANTS.SOURCE_ENUM.USER ? 'ais_sent' : 'ais_received';
        let initialMessage = props.message.data.text;

        let { HorizonItemInformation: { AssetCatalogName } = {} } = props.LFLDevice;
        let { deviceDetails } = this.props;
        let AssetCatName =
          deviceDetails &&
          deviceDetails.AssetCatalog &&
          deviceDetails.AssetCatalog.AssetCatalogName;
        let selectedDevice = !_.isEmpty(props.LFLDevice)
          ? getFormattedPopularName(AssetCatalogName)
          : deviceDetails
          ? getFormattedPopularName(AssetCatName)
          : {};

        let unavailableDevice =
          selectedDevice.makename || selectedDevice.modelName
            ? `${selectedDevice.makename} ${selectedDevice.modelName}`
            : 'selected above';

        // Smart Stock on SWAP workaround
        if (
          props.caseType === CONSTANTS.SWAP &&
          props.replacementDevices?.ReplacementItem.length > 0
        ) {
          const replacementItem = props.replacementDevices.ReplacementItem[0];
          unavailableDevice = `${
            replacementItem.HorizonItemInformation.AssetFamily
          } ${formatCapacity(replacementItem.HorizonItemInformation?.AssetCapacity)} ${
            replacementItem.HorizonItemInformation.AssetColor
          }`;
        }

        let finalTxtMsg =
          _.isString(initialMessage) && initialMessage.indexOf('{UnavailableDevice}') !== -1
            ? replacePlaceholders(initialMessage, '{UnavailableDevice}', unavailableDevice)
            : initialMessage;

        finalTxtMsg =
          _.isString(finalTxtMsg) && finalTxtMsg.indexOf('{Username}') > -1
            ? replacePlaceholders(finalTxtMsg, '{Username}', props.customerName.firstName)
            : finalTxtMsg;

        finalMessage = (
          <div className={messageBlockClass}>
            <div className={messageDivClass}>
              <div className={messageTimeClass}>
                {props.message.time &&
                  moment(props.message.time).format(CONSTANTS.MESSAGE_TIME_FORMAT)}
              </div>
              <div className={contentClass}>
                <TextMessage textMessage={finalTxtMsg} isText />
              </div>
            </div>
          </div>
        );
        break;
      case CONSTANTS.CONTINUE_PREVIOUS_SR:
        finalMessage = (
          <ResumeServiceRequest
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SOURCE_USER_INPUT.DEVICE_LIST_SUBSCRIPTION:
        finalMessage = (
          <DeviceList
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SOURCE_USER_INPUT.VERIFIED_AGREEMENT_DEVICE_LIST:
        finalMessage = (
          <VerifiedDeviceList
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SOURCE_USER_INPUT.SERVICE_UNAVAILABLE:
        finalMessage = (
          <ServiceUnavailable
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;

      case CONSTANTS.ASK_FOR_ENQUIRY:
        finalMessage = (
          <AskForEnquiry
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );

        break;

      case CONSTANTS.RESUME_FLOW_FOR_HOLD:
        finalMessage = (
          <ResumeReqForHold
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;

      case CONSTANTS.NO_PAYMENT_REVIEW:
        finalMessage = (
          <ResumeReqForHold
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;

      case CONSTANTS.ENQUIRY_OPTIONS:
        finalMessage = (
          <EnquiryOptionsSA
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SELECTED_ENQUIRY_OPTIONS:
        finalMessage = (
          <SelectedEnquiryOptions
            dataprops={props.message.dataprops}
            enquiryType="replacement"
            resumePoint={props.resumePoint}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SELECTED_REQUEST_OPTIONS:
        finalMessage = (
          <SelectedRequestOptions
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.GENERAL_ENQUIRY:
        finalMessage = (
          <GeneralEnquiry
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.IMEI_ATTEMPTS_EXCEEDED:
        finalMessage = (
          <EnquiryOptions
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.DEVICE_IS_BROKEN:
        finalMessage = (
          <EnquiryOptions
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.DEVICE_NOT_WORKING:
        finalMessage = (
          <EnquiryOptions
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.REQUEST_OPTIONS:
        finalMessage = (
          <EnquiryOptions
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.INCORRECT_MDN_SECOND_ATTEMPT:
        finalMessage = (
          <MDNIncorrect
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.MDN_VERIFICAIION_ERROR:
        finalMessage = (
          <InformativeMessage
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            source={CONSTANTS.SOURCE_USER_INPUT.MDN}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.INCORRECT_NAME_SECOND_ATTEMPT:
        finalMessage = (
          <RequestForMDNAndNRIC
            data={componentMsgObj}
            time={props.message.time}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.NAME_VERIFICAIION_ERROR:
        finalMessage = (
          <InformativeMessage
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.NO_EXISTING_SR:
        finalMessage = (
          <InformativeResumeMessage
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.USER_DISCONNECTED:
        finalMessage = (
          <InformativeMessage
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
        break;
      case CONSTANTS.AGENT_DISCONNECTED:
        finalMessage = (
          <DesktopAgentStatus
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.ASSIGNED_AGENT_BUSY:
        finalMessage = (
          <InformativeMessage
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.ALL_AGENT_BUSY:
        finalMessage = (
          <DesktopAgentStatus
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.ALL_AGENT_OFFLINE:
        finalMessage = (
          <DesktopAgentStatus
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      /* case CONSTANTS.ALL_AGENT_BUSY_MODAL:
        finalMessage = <ModalAgentStatus areBusyVisible={props.areBusyVisible} data={componentMsgObj} source={CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_BUSY_MODAL} updateJourneyList={props.updateJourneyList} />;
        break;
      case CONSTANTS.ALL_AGENT_OFFLINE_MODAL:
        finalMessage = <ModalAgentStatus areOfflineVisible={props.areOfflineVisible} data={componentMsgObj} source={CONSTANTS.SOURCE_USER_INPUT.ALL_AGENT_OFFLINE_MODAL} updateJourneyList={this.updateJourneyList} />;
        break; */
      /*       case CONSTANTS.ACCOUNT_VERIFICATION_IN_PROGRESS:
              finalMessage = <AjaxLoader data={componentMsgObj} shouldShowFailureLabel={!props.NRICVerified} shouldHideAfterCompletion={true} trickleStep={25} trickle={true} trickleInterval={500} progressValue={props.verificationProgressValue} startLabel={MESSAGE_CONSTANTS.ACCOUNT_BEING_VERIFIED} completeLabel={MESSAGE_CONSTANTS.ACCOUNT_VERIFIED} failureLabel={MESSAGE_CONSTANTS.ACCOUNT_UNVERIFIED} updateJourneyList={props.updateJourneyList} />;
              break; */
      case CONSTANTS.TRANSFERRED_TO_AGENT:
        finalMessage = (
          <AjaxLoader
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            // progressValue={props.verificationProgressValue ?? 100}
            progressValue={props.verificationProgressValue || 100}
            completeLabel={MESSAGE_CONSTANTS.TRANSFERRED_TO_AGENT}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.INCORRECT_NRIC_SECOND_ATTEMPT:
        finalMessage = (
          <InformativeMessage
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            /* source={CONSTANTS.SOURCE_USER_INPUT.NRIC} */ updateJourneyList={
              props.updateJourneyList
            }
          />
        );
        break;
      case CONSTANTS.NRIC_VERIFICAIION_ERROR:
        finalMessage = (
          <InformativeMessage
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            source={CONSTANTS.SOURCE_USER_INPUT.NRIC}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.CONFIRM_CURRENT_DEVICE:
        finalMessage = (
          <ConfirmCurrentDevice
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.AVAIL_DEVICE:
        finalMessage = (
          <ConfirmCurrentDevice
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.PROCEED_SERVICE_FEE:
      case CONSTANTS.COD_CONFIRMATION:
      case CONSTANTS.SHOW_PAYMENTFEE_WITH_DISCOUNT:
        finalMessage = (
          <ProceedServiceFee
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.DISABLED_PROCEED_SERVICE_FEE:
        finalMessage = (
          <DisabledProceedServiceFee
            data={componentMsgObj}
            source={CONSTANTS.SOURCE_USER_INPUT.SERVICE_FEE_PROCEED}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.CONFIRM_ENROLLED_ADDRESS:
        finalMessage = (
          <ConfirmEnrolledAddress
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      //ashwin
      case CONSTANTS.FINAL_CONFIRM_ENROLLED_ADDRESS:
        finalMessage = (
          <ConfirmEnrolledAddress
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.CONFIRM_EMAIL_ADDRESS:
        finalMessage = (
          <ConfirmEnrolledAddress
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.CONFIRM_MOB_NO:
        finalMessage = (
          <ConfirmEnrolledAddress
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.PAYMENT_FEE:
        finalMessage = (
          <ProceedServiceFee
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.WEB_CREDITCARD_FORM:
        // finalMessage = (
        //   <WebCreditCardForm
        //     data={componentMsgObj}
        //     dataprops={props.message.dataprops}
        //     updateJourneyList={props.updateJourneyList}
        //   />
        // );
        finalMessage = (
          <WebCreditCardFormNew
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.WEB_SCHEDULE_FORM:
        finalMessage = (
          <ChangeDeliverySchedule
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.CONFIRM_DELIVERY_SCHEDULE:
        finalMessage = (
          <ConfirmDeliverySchedule
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_MY_DELIVERY:
        finalMessage = (
          <ChangeDeliveryScheduleEnquiry
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS:
        finalMessage = (
          <ChangeDeliveryAddressEnquiry
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SOURCE_USER_INPUT.I_HAVE_AN_ENQUIRY:
        finalMessage = (
          <CancelDeliveryEnquiry
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.ALTERNATE_DEVICE_LIST:
        finalMessage = (
          <SlideAlternativeDevices
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.ASK_FOR_SURVEY:
        finalMessage = (
          <AskFeedback
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.TRIAGE_QUESTION:
        finalMessage = (
          <CustomerSupportChat
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.TRIAGE_ISSUE_RESOLVED:
        finalMessage = (
          <CustomerSupportChat
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.TRIAGE_FAILED:
        finalMessage = (
          <DisclaimerWithButtons
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.TURN_OFF_FMIP:
        finalMessage = (
          <TurnOffFindMyPhone
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.TURN_OFF_FMIP_INSTRUCTIONS:
        finalMessage = (
          <FMIPInstructionSlider data={componentMsgObj} dataprops={props.message.dataprops} />
        );
        break;
      case CONSTANTS.CONFIRM_SIM_CARD:
        finalMessage = (
          <CustomerSupportChat
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.CONFIRM_DETAILS:
        finalMessage = (
          <ConfirmYourDetails
            selectedReplacementDevice={this.props.selectedReplacementDevice}
            dataprops={props.message.dataprops}
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.CURRENT_REQUEST_DETAILS:
        finalMessage = (
          <CurrentRequestDetails dataprops={props.message.dataprops} data={componentMsgObj} />
        );
        break;
      case CONSTANTS.CURRENT_DEVICE_OPTIONS:
        finalMessage = (
          <CurrentDeviceOptions
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.ASK_FOR_FEEDBACK:
        finalMessage = (
          <ProceedServiceFee
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SURVEY_QUESTION_RATING:
        finalMessage = (
          <SurveyQuestion
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SURVEY_QUESTION_COMMENTS:
        finalMessage = (
          <SurveyQuestion
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SURVEY_QUESTION_EXPERIENCE:
        finalMessage = (
          <SurveyQuestion
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.WEB_CHANGE_DELIVERY_ADDRESS:
        finalMessage = (
          <WebChangeDeliveryAddress
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      // Accept terms and conditions.
      case CONSTANTS.ACCEPT_TERMS:
        finalMessage = (
          <AcceptTerms
            data={componentMsgObj}
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      // Check Alternative devices.
      case CONSTANTS.CHECK_ALTERNATIVE_DEVICES:
        finalMessage = (
          <CheckAlternativeDevice
            dataprops={props.message.dataprops}
            updateJourneyList={props.updateJourneyList}
            data={componentMsgObj}
          />
        );
        break;
      // Confirm Selected Device.
      case CONSTANTS.CONFIRM_SELECTED_DEVICE:
        finalMessage = (
          <ConfirmSelectedDevice
            cacheId={props.cacheId}
            serviceFee={props.fee}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      // Drop a message.
      case CONSTANTS.DROP_MESSAGE:
        finalMessage = <DropMessage />;
        break;
      case CONSTANTS.CONFIRM_EXISTING_CUSTOMER:
        finalMessage = (
          <FAQPage
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.FAQ_PAGE:
        finalMessage = (
          <FAQPage
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.GE_INQUIRY_REASON:
        finalMessage = (
          <GeneralEnquiryReason
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.NO_EXISTING_CUSTOMER:
        finalMessage = (
          <CODForCreditCard
            data={componentMsgObj}
            source={CONSTANTS.CLOSE_SR}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
        break;
      // case CONSTANTS.CLOSE_REQUEST:
      //   finalMessage = <CODForCreditCard data={componentMsgObj} source={CONSTANTS.CLOSE_REQUEST} updateJourneyList={props.updateJourneyList} dataprops={props.message.dataprops}
      //     desktopConversationEnd={props.desktopConversationEnd} />
      //   break;
      // Display Enquiry options.
      case CONSTANTS.GRID_ENQUIRY_OPTIONS:
        // finalMessage = <GridViewOptions />;
        finalMessage = <DropMessage />;
        break;
      // Display Thank you message.
      case CONSTANTS.SENT_MESSAGE_SUCCEED:
        finalMessage = <SentMessage />;
        break;
      // Submit contact number details.
      case CONSTANTS.SUBMIT_CONTACT_NUMBER:
        finalMessage = (
          <SubmitContactNumber
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.SUBMIT_EMAIL:
        finalMessage = (
          <SubmitContactNumber
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      // Submit INEI Number Details.
      case CONSTANTS.SUBMIT_IMEI_NUMBER:
        finalMessage = (
          <SubmitIMEICode
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.TURNOFF_FEATURES:
        finalMessage = (
          <TurnOffFeatures
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.CC_ATTEMPT_ERROR:
        finalMessage = (
          <InformativeMessage
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.ADDRESS_ATTEMPT_ERROR:
        finalMessage = (
          <InformativeMessage
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      // Web Grid view options.
      case CONSTANTS.WEB_GRID_VIEW_OPTIONS:
        finalMessage = (
          <GridViewOptions
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      // Web Track my delivery.
      case CONSTANTS.WEB_TRACK_MY_DELIVERY:
        finalMessage = (
          <WebTrackMyDelivery data={componentMsgObj} dataprops={props.message.dataprops} />
        );
        break;
      // Device Track my delivery.
      case CONSTANTS.DOWNLOAD_DOCUMENTS:
        finalMessage = (
          <DownloadDocument
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.UPLOAD_DOCUMENTS:
        finalMessage = (
          <UploadDocument
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.CONFIRM_UPLOAD_DOCUMENTS:
        finalMessage = (
          <SelectUploadDocument
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.CONFIRM_UPLOAD_SR:
        finalMessage = (
          <UploadSRForm
            message={props.message}
            progressValue={props.progressValue}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.HOLD_SCRIPT:
        finalMessage = (
          <UploadFileScript
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;

      case CONSTANTS.ELIGIBILITY_VOID:
        finalMessage = (
          <UploadFileScript
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;

      case CONSTANTS.NO_PAYMENT_REVIEW:
        finalMessage = (
          <DisplayScript
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.ASK_FOR_MDN:
        finalMessage = (
          <RequestForMDNAndNRIC
            data={componentMsgObj}
            source={CONSTANTS.SOURCE_USER_INPUT.MDN}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.ASK_FOR_NRIC:
        finalMessage = (
          <RequestForMDNAndNRIC
            data={componentMsgObj}
            time={props.message.time}
            source={CONSTANTS.SOURCE_USER_INPUT.NRIC}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.ASK_FOR_LEAVING_AIS_REASON:
        finalMessage = (
          <RequestForLeavingAisReason
            data={componentMsgObj}
            time={props.message.time}
            source={CONSTANTS.SOURCE_USER_INPUT.ASK_FOR_LEAVING_AIS_REASON}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.AIS_REASON_NOT_HELPFULL:
        finalMessage = (
          <AISReasonCommonPage
            data={componentMsgObj}
            time={props.message.time}
            source={CONSTANTS.SOURCE_USER_INPUT.AIS_REASON_NOT_HELPFULL}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.LOAD_AIS_SUBREASON:
        finalMessage = (
          <AISSubReasonPage
            data={componentMsgObj}
            time={props.message.time}
            source={CONSTANTS.SOURCE_USER_INPUT.LOAD_AIS_SUBREASON}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.LOAD_THANK_YOU:
        finalMessage = (
          <AISThankYouPage
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      /* case CONSTANTS.ROUTE_TO_SAVEDESK:
        finalMessage = <RouteToSveDesk data={componentMsgObj} time={props.message.time} updateJourneyList={props.updateJourneyList} dataprops={props.message.dataprops}></RouteToSveDesk>
        break; */
      case CONSTANTS.ROUTE_TO_REFUND:
        finalMessage = (
          <RouteToRefund
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.ROUTE_TO_SAVEDESK:
        finalMessage = (
          <Acknowledge
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.ENTER_OTHER_REASON:
        finalMessage = (
          <OtherReason
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.LEAVING_AIS_POP_UP:
        finalMessage = (
          <ChurnPopup
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;

      /* case CONSTANTS.WBKO:
        finalMessage = <Wbko data={componentMsgObj} time={props.message.time} updateJourneyList={props.updateJourneyList} dataprops={props.message.dataprops}></Wbko>
        break; */
      case CONSTANTS.WBKO_TRACK_MY_DELIVERY:
        finalMessage = (
          // <WbkoTrackMyDelivery
          <WbkoTrackMyDeliveryNew
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS:
        finalMessage = (
          <WbkoUpdateContactDetails
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.WBKO_TRACK_OR_END:
        finalMessage = (
          <WbkoTrackOrEnd
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.WBKO_CANCELLATION_REASON:
        finalMessage = (
          <WbkoCancellationReason
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;

      case CONSTANTS.WBKO_TRACK_MY_DELIVERY_NEW:
        finalMessage = (
          <WbkoTrackMyDeliveryNew
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;

      case CONSTANTS.WBKO_CANCELLATION_CONFIRM:
        finalMessage = (
          <WbkoCancelationConfirm
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;

      case CONSTANTS.WBKO_CANCELLATION_REASON_NEW:
        finalMessage = (
          <WbkoCancelationReasonNew
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;

      case CONSTANTS.ASK_FOR_NAME:
        finalMessage = (
          <RequestForMDNAndNRIC
            data={componentMsgObj}
            time={props.message.time}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.JOURNEY_ENDED:
        if (this.props.journeyStatus !== CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.COMPLETED) {
          MIXPANEL.track(EVENTLIST.JOURNEY_COMPLETED, {
            'End Reason': props.message.data.endReason,
          });
          this.props.setJourneyCmpltd(true, CONSTANTS.ONLINE_JOURNEY_STATUS_LIST.COMPLETED);
        }
        break;
      case CONSTANTS.CLOSE_SR:
        finalMessage = (
          <CODForCreditCard
            data={componentMsgObj}
            source={CONSTANTS.CLOSE_SR}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
      case CONSTANTS.CLOSE_REQUEST:
        finalMessage = (
          <CODForCreditCard
            data={componentMsgObj}
            source={CONSTANTS.CLOSE_REQUEST}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
      case CONSTANTS.DEVICE_NOT_ELIGIBLE:
        finalMessage = (
          <CODForCreditCard
            data={componentMsgObj}
            source={CONSTANTS.DEVICE_NOT_ELIGIBLE}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
      case CONSTANTS.NO_ALTERNATIVE_DEV_INSTOCK:
        finalMessage = (
          <CODForCreditCard
            data={componentMsgObj}
            source={CONSTANTS.NO_ALTERNATIVE_DEV_INSTOCK}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
        break;
      case CONSTANTS.MDN_NRIC_CLOSE:
        finalMessage = (
          <CODForCreditCard
            data={componentMsgObj}
            source={CONSTANTS.MDN_NRIC_CLOSE}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
        break;
      case CONSTANTS.GENERIC_NOT_RESOLVED:
        finalMessage = (
          <CODForCreditCard
            data={componentMsgObj}
            source={CONSTANTS.GENERIC_NOT_RESOLVED}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
        break;
      case CONSTANTS.ALTERNATE_DEVICE_NOTE:
        finalMessage = (
          <CODForCreditCard
            data={componentMsgObj}
            source={CONSTANTS.ALTERNATE_DEVICE_NOTE}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.COD_FOR_CREDIT_CARD:
        finalMessage = (
          <CODForCreditCard
            data={componentMsgObj}
            source={CONSTANTS.COD_FOR_CREDIT_CARD}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.CONFIRM_MALFUNCTION_DEVICE_MEASURES:
        finalMessage = (
          <TriageConfirmBox
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.CAPTURE_IW_REMARKS:
        finalMessage = (
          <TextAreaWithSubmit
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
          />
        );
        break;
      case CONSTANTS.GET_SERVICE_FEE:
        finalMessage = (
          <GetServiceFee data={componentMsgObj} updateJourneyList={props.updateJourneyList} />
        );
        break;
      case CONSTANTS.GET_MOREINFO:
        finalMessage = (
          <GetMoreInfo data={componentMsgObj} updateJourneyList={props.updateJourneyList} />
        );
        break;
      case CONSTANTS.SUBSCRIPTION:
        finalMessage = (
          <SubscriptionDetails data={componentMsgObj} updateJourneyList={props.updateJourneyList} />
        );
        break;
      case CONSTANTS.GET_STARREQ:
        finalMessage = (
          <StartRequest data={componentMsgObj} updateJourneyList={props.updateJourneyList} />
        );
        break;
      case CONSTANTS.UPDATE_ENROLLED_DEVICE:
        finalMessage = (
          <UpdateEnrolledDevice
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.ENROLLED_DEVICE_ENQUIRY:
        finalMessage = (
          <EnrolledDeviceInquiry
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
          />
        );
        break;
      case CONSTANTS.SMART_STOCK:
        finalMessage = (
          <SmartStock devices={props.message.data.devices} onDone={props.message.data.onDone} />
        );
        break;

      case CONSTANTS.DEVICE_IN_STOCK:
        finalMessage = (
          <DeviceInStock
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
        break;

      case CONSTANTS.DEVICE_OUT_STOCK:
        finalMessage = (
          <DeviceOutStock
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
        break;

      case CONSTANTS.DEVICE_NLP:
        finalMessage = (
          <DeviceNLP
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
        break;

      case CONSTANTS.DEVICE_NCA:
        finalMessage = (
          <DeviceNCA
            data={componentMsgObj}
            updateJourneyList={props.updateJourneyList}
            dataprops={props.message.dataprops}
            desktopConversationEnd={props.desktopConversationEnd}
          />
        );
        break;
        
      default:
        finalMessage = '';
    }
    return finalMessage;
  }

  handlePrevSubmit() {
    let { userInputState, setInputState } = this.props;
    if (!_.isEmpty(userInputState)) {
      setInputState(null);
      this.renderOutputMessage(userInputState);
    }
  }

  renderOutputMessage(userInputState) {
    // agent output to user
    let { value, source, error, author = `user` } = userInputState;
    this.props.updateJourneyList(source, value, author, error);
    this.setState({ value: '' });
  }

  render() {
    this.handlePrevSubmit();
    if (!this.props.message) return null;
    return this._renderMessageOfType(this.props.message.type);
  }
}

const mapStateToProps = (state) => {
  const {
    journeyStatus,
    backOrderCase,
    noServiceFee,
    verificationProgressValue,
    paymentProgressValue,
    verifiedNRIC,
    simRequired,
    verificationFailedAttempt,
    agreementFailedAttempt,
    inputState,
    replacementDevices,
    selectedReplacementDevice,
    baseItemId,
    serviceFee,
    emailAddress,
    phoneNumber,
    warehouseId,
    nameVerified,
    postalCode,
    enrolledPostalCode,
    agreementFound,
    addressList,
    shippingMethodsResult,
    shippingOrderResult,
    holds,
    voidScript,
    deviceDetails,
    serviceRequestType,
    chargeOrderDetails,
    selectedDefectOption,
    caseType,
    nameVerificationAttempt,
    assetDetails,
    makeAndModelList,
    selectedBrand,
    customerName,
    resumePoint,
    resumeCanceled,
    addressRetryAttempt,
    checkAvailableDeviceProgress,
    addressValidationLoaderValue,
    ccdetailsProgressValue,
    processIncidentProgress,
    determineIncidentResponse,
    triageSuccessfulFlag,
    warrantyType,
    lexProgressValue,
    mismatchedDeviceDetails,
    ccdetailsAccepted,
    deliveryWindow,
    deliveryDate,
    customerCase,
    selectedDeliveryDate,
    LFLDevice,
    LFLDeviceInStock,
    NLFLDeviceInStock,
    selectedEnquiryOption,
    serviceRequestId,
  } = state.customerData;
  let deviceList = replacementDevices ? replacementDevices.ReplacementItem : [];
  return {
    journeyStatus,
    ccdetailsAccepted,
    isBackOrderCase: backOrderCase,
    simRequired,
    customerCase,
    triageSuccessfulFlag,
    ccdetailsProgressValue,
    processIncidentProgress,
    lexProgressValue,
    addressValidationLoaderValue,
    checkAvailableDeviceProgress,
    verificationProgressValue,
    deviceList,
    nameVerificationAttempt,
    selectedDefectOption,
    resumePoint,
    LFLDevice: LFLDevice || {},
    LFLDeviceInStock: LFLDeviceInStock || false,
    NLFLDeviceInStock: NLFLDeviceInStock || false,
    resumeCanceled,
    serviceRequestType,
    nameVerified,
    verificationFailedAttempt,
    serviceFee,
    agreementFound,
    paymentProgressValue,
    agreementFailedAttempt,
    replacementDevices,
    baseItemId,
    emailAddress,
    postalCode,
    enrolledPostalCode,
    warehouseId,
    addressList,
    caseType,
    assetDetails,
    makeAndModelList,
    selectedBrand,
    customerName,
    deviceDetails,
    deliveryWindow,
    deliveryDate,
    mismatchedDeviceDetails,
    noServiceFee,
    enrolledDeviceInWarranty: warrantyType === CONSTANTS.IN_WARRANTY,
    deviceMake: selectedBrand || deviceDetails ? deviceDetails.Make : '',
    NRICVerified: verifiedNRIC,
    selectedReplacementDevice: LFLDeviceInStock ? LFLDevice : selectedReplacementDevice,
    userInputState: inputState,
    customerContact: phoneNumber,
    expectedDeliveryDate: shippingMethodsResult
      ? shippingMethodsResult.EstimatedDeliveryDate
      : null,
    slot: shippingMethodsResult ? shippingMethodsResult.ShippingMethodType : null,
    shippingMethodsResult: shippingMethodsResult,
    shippingOrderResult: shippingOrderResult,
    fee: serviceFee ? serviceFee.CurrencyCode + ' ' + serviceFee.TotalAmount : null,
    cardType: chargeOrderDetails ? chargeOrderDetails.CardBrand : null,
    chargeOrderDetails: chargeOrderDetails,
    isChargeOrderSuccess: chargeOrderDetails ? chargeOrderDetails.isChargeOrderSuccess : false,
    cacheId: state.customerData.clientData.CacheId,
    clientId: state.customerData.clientData.ClientId,
    holds: holds || [],
    voidScript: voidScript,
    addressRetryAttempt: addressRetryAttempt,
    currentRequestDetails: determineIncidentResponse,
    customerCaseNumber: determineIncidentResponse
      ? determineIncidentResponse.CustomerCaseNumber
      : '',
    selectedDeliveryDate: selectedDeliveryDate,
    selectedEnquiryOption,
    EnquiryForChangeDeliveryDate: determineIncidentResponse
      ? determineIncidentResponse.ExpectedDeliveyDate
      : '',
    serviceRequestId: serviceRequestId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setInputState,
      setJourneyCmpltd,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
